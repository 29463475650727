
<template>
  <div>
    <v-card flat style='border-radius: 18px 18px 0px 0px;'>
      <v-card-title class='primary white--text py-3 px-5'>
        <span v-if="$route.name =='NewLogo'">
          Cadastrar nova {{ ($route.params.kind == 'country_flag' || general_image.kind == 'country_flag') ? 'logo ministério' : 'logo'}}
        </span>
        <span v-if="$route.name =='EditLogo'">
          Visualização da {{ ($route.params.kind == 'country_flag' || general_image.kind == 'country_flag') ? 'logo ministério' : 'logo'}}
        </span>
        <v-spacer></v-spacer>
        <v-btn 
          text 
          rounded
          color="white" 
          v-if="$route.name =='EditLogo'" 
          @click="enable_fields = !enable_fields"
        ><v-icon left>mdi-pencil</v-icon> Habilitar edição</v-btn>
        <v-btn 
          text 
          rounded
          icon 
          color="white"
          class="ml-2" 
          @click="$router.push({name: 'CertificateConfig'})" 
        ><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pt-3" >
        <card-loading-transparent v-if="loading"/>
        <v-form ref="form" v-if="!loading">
          <v-text-field
            v-model="general_image.name"
            prepend-icon="mdi-briefcase-edit-outline"
            label="Nome"
            :rules="required"
            :readonly="enable_fields && !!$route.params.id"
          ></v-text-field>
          <div style="overflow-x: auto;" class="d-flex justify-center">
            <img :src="local_file_preview ? local_file_preview.url : attached_file_url"/>
          </div>
          <v-file-input
            :disabled="enable_fields && !!$route.params.id"
            :label="attached_file_url ? 'Alterar logo' : 'Selecionar logo'"
            accept="image/jpg, image/png, image/jpeg, application/pdf" 
            v-model="file"
            @change="createLocalUrlFile()"
            :rules="this.$route.name == 'NewLogo' ? required : []"
          ></v-file-input>

          <div v-if="![general_image.kind, $route.params.kind].includes('country_flag')">
            <div class="font-weight-bold pt-4">Selecione a posição da logo:</div>
            <v-radio-group v-model="general_image.kind" :readonly="enable_fields && !!$route.params.id">
              <v-radio label="Esquerdo" value="logo_left"></v-radio>
              <v-radio label="Lado direito" value="logo_right"></v-radio>
            </v-radio-group>
          </div>

        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center mb-3">
        <v-btn close @click="updateFile()" color="success"><v-icon left>mdi-content-save</v-icon>Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import validations from '@/mixins/validations'
import Api from '@/api/index';

export default {
  name: 'GeneralImagesEdit',
  mixins: [ validations ],
  data() {
    return {
      local_file_preview: null,
      attached_file_url: '',
      file: null,
      saving: false,
      loading: false,
      general_image: {},
      enable_fields: true
    }
  },
  methods: {
    getImage(){
      if(this.$route.params.id){
        this.loading = true
        Api.GeneralImages.show(this.$route.params.id).then(r=>{
          this.attached_file_url = r.data.attached_file_url
          this.general_image = r.data
        }).catch((err)=>{
          console.log(err)
        }).finally(() =>{
          this.loading = false
        })
      }
    },
    createLocalUrlFile(){
      if(this.file){
        var url  = window.URL.createObjectURL(this.file)
        this.local_file_preview = {
          url: url,
          name: this.file.name
        }
      }else{
        this.local_file_preview = null;
      }
    },
    updateFile(id){
      if (this.$refs.form.validate()) {
        this.saving = true
        let form_data = this.prepareFormData(id)
        let METHOD = this.$route.params.id ? 'update' : 'create'
        let success = false
        Api.GeneralImages[METHOD](form_data).then((r)=>{
          success = true
          this.addNotification({type: 'success', msg: METHOD == 'update' ? 'Logo atualizada com sucesso.' : 'Logo cadastrada com sucesso.'})
        }).catch((err)=>{
          console.log(err)
          let err_msg = err.response?.data?.base[0] ? err.response?.data?.base[0] : 'O anexo não foi salvo, tente editar e altera-lo novamente.'

          this.addNotification({type: 'error', msg: err_msg})
        }).finally(()=>{
          this.saving = false
          if(success)this.$router.push({name: 'CertificateConfig', params: {updated_file: true}})
        })
      }
    },
    prepareFormData(){
      const formData = new FormData()

      if(this.$route.params.id){
        formData.append(`general_image[id]`, parseInt(this.$route.params.id));
      }
      if(this.file){
        formData.append(`general_image[attached_file]`, this.file);
      }
      formData.append(`general_image[name]`, this.general_image.name);
      formData.append(`general_image[kind]`, this.general_image.kind || this.$route.params.kind);

      return formData
    },
    cancelEdit(){
      this.change_file = !this.change_file
      this.local_file_preview = null
    
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  mounted () {
    this.getImage();
  },
}
</script>

<style scoped>

</style>